<template>
<div class="px-2">
   <div class="flex justify-center my-10 text-center">
     <div>
       <p class="text-2xl font-semibold text-gray-700 md:text-4xl">Proveedores</p>
     <!-- <div v-if="location"> -->
      <p class="pt-1 text-sm text-black">Tenemos todos tus proveedores favoritos aquí</p>
    <!-- </div> -->
     </div>
     
  </div>
  <vue-horizontal responsive>
    <div  v-for="(vendor) in vendors" :key="vendor.id" class="grid grid-cols-1 gap-4 px-3">
      <a :href="$router.resolve({name: 'Vendor', params: { id: vendor.id, slug: sanitizeTitle(`${vendor.name}`) }}).href" class="w-full h-56 md:w-96 md:h-full">
        <img  v-if="vendor" v-bind:src="vendor.feature_image" class="transition duration-500 ease-in-out transform shadow-sm h-28 rounded-t-md hover:-translate-y-1 hover:scale-100 md:h-56 w-72 md:w-96">
        <div class="p-3 shadow-sm w-72 md:w-96 rounded-b-md bg-gray-50">
            <p class="mt-2 mb-1 font-semibold truncate">{{ vendor.name }}</p>
            <div class="flex flex-row ">
               <p class="flex py-1">
                <span class="font-light " v-for="star in vendor.rating" :key="star.id">
                  <starIcon {{ star }} class="self-center w-3 h-3 text-yellow-400 md:mt-0 md:w-6 md:h-6" aria-hidden="true" />
                </span>
              </p>
            </div>
            <p class="text-xs font-light text-gray-700 truncate">{{ vendor.address}}</p>
        </div>
      </a>
    </div>
  </vue-horizontal>
</div>
 
</template>
<script>
import VueHorizontal from "vue-horizontal"
import axios from 'axios'
import { StarIcon } from '@heroicons/vue/solid'
export default {
  name: 'Vendors',
  components: {
    VueHorizontal,
    StarIcon,
  },
  
  data () {
    return {
      vendors: null,
      settings: null,
      base_url: this.$store.state.baseUrl
    }
  },
  
  mounted () {
    axios.get(this.base_url+'api/vendors?vendor_type_id='+2)
    .then((response) => {
      this.vendors = response.data.data
      // console.log(this.vendors)
      }
      
    )
    .catch(error => console.log(error))

  },

  methods: {
    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd');
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '');
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-');
      
      return slug;
    },

    
  },

}
</script>
