<template>
<div class="flex hidden md:block" v-if="settings">
  <div class="w-full h-128 bg-auto bg-no-repeat bg-center lg:bg-cover relative" :style="{ 'background-image': 'url(/img/principal.jpg)'}">
    <div class="container flex px-5 mx-auto md:justify-between md:max-w-6xl ">
      <div class="flex items-center justify-center py-16">
        <div class="self-center px-10">
          <h2 class="text-3xl font-extrabold leading-tight text-white md:text-4xl md:tracking-wide md:leading-normal">
            
            <!-- Book your favourite restaurant with lovely menu -->
          </h2>
          <p class="mt-3 text-xl font-normal tracking-widest text-gray-200 md:text-2xl">
            
            <!-- Crispy Tofu Salad Greens & Honey Chilli -->
          </p>
          <!-- <div class="items-stretch w-full mt-12 md:flex">
            <button v-on:click="toggleModal()" class="block w-full px-5 py-4 mt-2 font-semibold bg-white rounded-md shadow hover:text-white md:mt-0 hover:bg-gray-100" type="button" :style="{ 'color': settings.colors.primaryColor }">
              Search product
            </button>
          </div> -->
          <!-- <div class="w-full px-4 py-3 mt-1 bg-white rounded-sm shadow-sm md:w-2/2">
              <div class="flex flex-col" v-if="products">
            
                <div  v-if="products.length <= 0">
                    <p class="mt-5 text-center">No Product</p>
                </div> 
                <div v-else>
                  <div v-for="product in products" :key="product.id">
                    <a :href="$router.resolve({name: 'Product', params: { id: product.id, slug: sanitizeTitle(`${product.name}`) }}).href">
                        <p class="font-semibold">{{ truncate(product.name, 15) }}</p>
                    </a>
                  </div>
                </div>
              </div>
          </div> -->
        </div>
      </div>
      
      <div class="hidden md:mx-auto md:m-10 md:block">
      </div>
    </div>
    
  </div>
</div>  
    <div class="flex justify-between mx-auto md:max-w-full">
        
        <div class="flex hidden md:block" v-if="settings">
            <img src="/img/web1.jpg" class="h-full w-full" alt="">
        </div>
        <div class="self-center max-w-4xl p-5" v-if="settings">
            <div class="flex flex-col mx-auto">
                <p class="text-xl font-semibold text-black md:text-2xl">La Expo Mundo Rural ahora es digital</p>
                <p class="pt-1 my-4 text-sm text-black">Con cientos de productos exclusivos de la región de Tarapacá. Instala hoy la aplicación y disfruta de despacho gratis, seguimiento de tu pedido en tiempo real, descuentos y nuevos productos todas las semanas</p>
                
            </div>
            <div class="">
                <p class="text-sm font-semibold">Elige lo que necesitas:</p>
                <div class="grid md:grid-cols-3 md:gap-x-5">
                   <div v-for="(vendor) in vendors" :key="vendor.id" class="my-4">
                            <a v-if="vendor" :href="`${vendor.id}/`+sanitizeTitle(`${vendor.slug}`)">
                                <div class="flex flex-col w-full h-full p-3 rounded-md shadow bg-gray-50">
                                    <img  v-if="vendor" v-bind:src="vendor.logo" class="w-22 h-22 mb-3">
                                    <p class="font-black">{{vendor.name}}</p>
                                    <p class="text-xs font-light">{{vendor.description}}</p>
                                </div>
                            </a>
                        
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div class="flex hidden md:block" v-if="settings">
            <img src="/img/web2.jpg" class="h-full w-full" alt="">
        </div>
    </div>
    <Download/> 
</template>
<script>
import axios from 'axios'
import { SearchIcon } from '@heroicons/vue/outline'
import { StarIcon } from '@heroicons/vue/solid'
import Download from '@/components/Downloads.vue'
export default {
  name: 'Vendors',
  components: {
    SearchIcon,
    StarIcon,
    Download,
  },
  
  data () {
    return {
      vendors: null,
      settings: null,
      base_url: this.$store.state.baseUrl,
      search: null,
      types: null
    }
  },
  
  mounted () {

    axios.get(this.base_url+'api/app/settings')
    .then((response) => {
        this.settings = response.data
    })
    .catch(error => console.log(error))

    axios.get(this.base_url+'api/vendor/types')
        .then((response) => {
            this.vendors = response.data
        }
    )
    .catch(error => console.log(error))

  },

  methods: {

    sanitizeTitle(title) {
      var slug = "";
      // Change to lower case
      var titleLower = title.toLowerCase();
      // Letter "e"
      slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
      // Letter "a"
      slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
      // Letter "o"
      slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
      // Letter "u"
      slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
      // Letter "d"
      slug = slug.replace(/đ/gi, 'd');
      // Trim the last whitespace
      slug = slug.replace(/\s*$/g, '');
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-');
      
      return slug;
    },

    
  },

}
</script>